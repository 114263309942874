import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { WalletDialogButton, WalletDisconnectButton } from "@solana/wallet-adapter-material-ui"
import { useWallet } from "@solana/wallet-adapter-react"
import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { connect } from "react-redux"
import { setWallet, setWalletProvider, setWalletAddress, setConnected } from "../../../state/actions"
import { Menus, icons } from "./menu"
import Footer from './Footer.js'
import "./Layout.scss"

const Layout = ({
  title = "Hvorigins",
  children,
  contentClassName = "",
  headerClass = "",
  headerStyle = null,
  visibleClass = true,
  visibleFooter = true,
  type,
  wallet,
  walletProvider,
  walletAddress,
  setWallet,
  setWalletProvider,
  setWalletAddress,
  setConnected
}) => {
  const walletInfo = useWallet();
  const [walletName, setWalletName] = useState('');
  const [isPhantom, setIsPhantom] = useState(true);

  useEffect(() => {
    const connectStatus = localStorage.getItem('hv_connect')
    const getProvider = () => {
      (async () => {
        if ("solana" in window) {
          const solProvider = (window).solana;
          if (solProvider.isPhantom)
            setWalletProvider(solProvider);
        }

        if (walletInfo.wallet) {
          setWalletName(walletInfo.wallet.name);
          if (walletInfo.wallet.name === 'Phantom') {
            localStorage.setItem('hv_phantom', true)
          } else {
            localStorage.setItem('hv_phantom', false)
          }
        }
      })();
    };

    if (connectStatus === 'true')
      getProvider()
  }, [walletInfo]);

  useEffect(() => {
    const connectStatus = localStorage.getItem('hv_connect')
    const choosePhatom = localStorage.getItem('hv_phantom')

    const initialHandler = async () => {
      if (walletName === 'Phantom' || choosePhatom === 'true') {
        setIsPhantom(true)
        if (walletProvider) {
          walletProvider.on("connect", () => {
            setWallet(walletProvider);
          });
          try {
            await walletProvider.connect({ onlyIfTrusted: true });
          } catch (err) {
            console.log('error', err);
          }
        }
      } else {
        setIsPhantom(false)
        if (walletInfo.publicKey) {
          setWallet(walletInfo);
        }
      }
    }

    if (connectStatus === 'true')
      initialHandler()
  }, [walletProvider, walletInfo]);

  useEffect(() => {
    if (wallet) {
      if (wallet.publicKey) {
        let wallet_address = wallet.publicKey.toString();
        setWalletAddress(wallet_address.substring(0, 4) + "..." + wallet_address.substring(wallet_address.length - 4));
        setConnected(true);
      } else {
        setWalletAddress('');
        setConnected(false);
      }
    } else {
      setWalletAddress('');
      setConnected(false);
    }

  }, [wallet]);

  const connectHandler = () => {
    localStorage.setItem('hv_connect', true);
  }

  const disconnectHandler = () => {
    localStorage.setItem('hv_connect', false);
    if (walletProvider !== null) {
      walletProvider.disconnect();
    }

    setWallet(null)
    setWalletProvider(null)
    setWalletName('')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={`Layout ${type}`}>
        <Disclosure
          as="nav"
          className={`header fixed z-120 w-full px-0 ${headerClass} ${visibleClass ? "block" : "hidden"} `}
          style={headerStyle}
        >
          {({ open }) => (
            <>
              <div className="container m-auto py-2 xl:px-12">
                <div className="relative flex items-center justify-between">
                  <div className="flex items-center justify-between flex-1 min-w-full">
                    <div className="flex items-center flex-shrink-0">
                      <Link to="/">
                        <img
                          className="block w-auto h-8 hv-logo"
                          src="/hv_logo.png"
                          alt="logo"
                        />
                      </Link>
                      <div className="hidden xl:flex items-center ml-10">
                        {icons.map((dt, idx) => {
                          return (
                            <a
                              className="flex items-center"
                              href={dt.link}
                              target="_blank"
                              rel="noreferrer"
                              key={idx}
                            >
                              <img src={dt.icon} alt={dt.name} className="mx-2 w-10" />
                            </a>
                          )
                        }
                        )}
                      </div>
                    </div>
                    <div className="hidden xl:block xl:ml-6 py-1">
                      <div className="flex space-x-4 align-middle h-full">
                        <ul className="navMenus">
                          {Menus.map(dt => (
                            <li className={`px-4 font-montserrat-bold uppercase italic ${dt.active === type ? "active" : ""}`} key={dt.name} >
                              {dt.type == 'internal' ?
                                <Link
                                  activeClassName="active"
                                  to={dt.path}
                                  partiallyActive={!!dt.partiallyActive}
                                  target={dt.target}
                                  className="line-clamp-1"
                                >
                                  {dt.name}
                                </Link>
                                :
                                <a href={dt.path} target={dt.target}>
                                  {dt.name}
                                </a>
                              }
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="hidden xl:flex h-full flex-row w-max items-center">
                      {walletAddress == "" ? (
                        <WalletDialogButton className="wallet-connect w-48" onClick={connectHandler}>
                          Connect
                        </WalletDialogButton>
                      ) : (
                        <div className="wallet-address flex items-center">
                          <div className="wallet text-white font-montserrat-bold mr-2">
                            {walletAddress}
                          </div>
                          {isPhantom ? (
                            <button onClick={disconnectHandler}>
                              <img src="/icon/icon_close.png" alt="close icon" className="h-8" />
                            </button>
                          ) : (
                            <WalletDisconnectButton className="wallet-disconnect h-8 px-0" onClick={disconnectHandler} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center xl:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="mr-3 inline-flex items-center justify-center p-2 rounded-md menuToggleIcon focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block w-6 h-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block w-6 h-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="bg-black-light xl:hidden">
                <div className="px-2 pt-2 pb-6 space-y-1">
                  <div>
                    <ul className="flex-col navMenus">
                      {Menus.map(dt => (
                        <li className="px-3 py-2 font-montserrat-bold uppercase italic" key={dt.name}>
                          <Link
                            activeClassName="active"
                            to={dt.path}
                            target={dt.target}
                          >
                            {dt.name}
                          </Link>
                        </li>
                      ))}
                      <li className="px-3 pt-4 font-montserrat-bold">
                        {walletAddress == "" ? (
                          <WalletDialogButton className="wallet-connect w-48" onClick={connectHandler}>
                            Connect
                          </WalletDialogButton>
                        ) : (
                          <div className="wallet-address flex items-center">
                            <div className="wallet text-white font-montserrat-bold mr-2">
                              {walletAddress}
                            </div>
                            {isPhantom ? (
                              <button onClick={disconnectHandler}>
                                <img src="/icon/icon_close.png" alt="close icon" className="h-8" />
                              </button>
                            ) : (
                              <WalletDisconnectButton className="wallet-disconnect h-8 px-0" onClick={disconnectHandler} />
                            )}
                          </div>
                        )}
                      </li>
                    </ul>
                    <div className="flex items-center justify-between max-w-xs mx-auto mt-10">
                      {icons.map((dt, idx) => {
                        return (
                          <a
                            className="flex items-center"
                            href={dt.link}
                            target="_blank"
                            rel="noreferrer"
                            key={idx}
                          >
                            <img src={dt.icon} alt={dt.name} className="mx-2 w-10" />
                          </a>
                        )
                      }
                      )}
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="contentWrapper relative flex flex-col">
          <main
            className={`w-full m-auto flex-grow flex flex-col main ${contentClassName} ${visibleClass ? "" : "pb-0"} content`}>
            {children}
          </main>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

const stateProps = (state) => ({
  wallet: state.wallet,
  walletProvider: state.walletProvider,
  walletAddress: state.walletAddress
});

const dispatchProps = (dispatch) => ({
  setWallet: (data) => dispatch(setWallet(data)),
  setWalletProvider: (data) => dispatch(setWalletProvider(data)),
  setWalletAddress: (address) => dispatch(setWalletAddress(address)),
  setConnected: (status) => dispatch(setConnected(status))
})

export default connect(stateProps, dispatchProps)(Layout);