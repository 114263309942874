import React from "react"
import { CollectionMenus, SiteMapMenus, icons } from "./menu"
import "./Layout.scss"

export default function Footer() {
    return (
        <div className="footerWrapper text-white px-10 py-10 z-110">
            <div className="container mx-auto font-montserrat uppercase italic xl:px-12">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
                    <div className="grid-item col-span-1 order-1 text-center md:text-left">
                        <span className="text-2xl font-montserrat-bold border-b-4 border-red-900 mx-auto px-10 md:pr-20 md:pl-0">
                            Collections
                        </span>
                        <div className="grid grid-cols-1 md:grid-cols-3 mt-6 gap-2">
                            {CollectionMenus.map((item, index) => {
                                return (
                                    <a className={`grid-item ${index % 2 === 0 ? "md:col-span-1" : "md:col-span-2"}`} href={item.target} key={index}>
                                        {item.name}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                    <div className="grid-item col-span-1 md:col-span-2 text-center mt-8 xl:mt-0 order-3 xl-order-2">
                        <span className="text-2xl font-montserrat-bold border-b-4 border-red-900 px-10 whitespace-nowrap">
                            Social Medias
                        </span>
                        <div className="mt-6">
                            <div className="flex items-center justify-center">
                                {icons.map((dt, idx) => {
                                    return (
                                        <a
                                            className="flex items-center"
                                            href={dt.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            key={idx}
                                        >
                                            <img src={dt.icon} alt={dt.name} className="mx-2 w-10" />
                                        </a>
                                    )
                                }
                                )}
                            </div>
                            <div className="mt-10">
                                <a href="https://masterbrews.com/" target="_blank" rel="noreferrer">
                                    <img src="/logo_powered.png" alt="powered" className="sm:max-w-sm m-auto" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item col-span-1 mt-6 md:mt-0 text-center md:text-left order-2 xl:order-3 md:ml-20">
                        <span className="text-2xl font-montserrat-bold border-b-4 border-red-900 px-10 md:pr-20 md:pl-0 whitespace-nowrap">
                            Site Map
                        </span>
                        <div className="grid grid-cols-1 md:grid-cols-3 mt-6 gap-2">
                            {SiteMapMenus.map((item, index) => {
                                return (
                                    <a className={`grid-item ${index % 2 === 0 ? "md:col-span-1" : "md:col-span-2"}`} href={item.path} key={index}>
                                        {item.name}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
