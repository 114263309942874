export const Menus = [
  {
    name: "Home",
    path: "/",
    target: "",
    type: "",
    active: "home"
  },
  {
    name: "Stake",
    path: "/stake",
    target: "",
    type: "",
    active: "stake"
  },
  {
    name: "P2E game",
    path: "#",
    target: "",
    type: "",
    active: "game"
  },
  {
    name: "My NFTs",
    path: "/mynfts",
    target: "",
    type: "",
    active: "mynft"
  },
  {
    name: "Passes",
    path: "/pass",
    target: "",
    type: "",
    active: "pass"
  },
  {
    name: "Comics",
    path: "/comic",
    target: "",
    type: "",
    active: "comic"
  },
];

export const CollectionMenus = [
  {
    name: "Origins",
    path: "#",
    target: "",
    type: "",
  },
  {
    name: "Sidekicks & Henchmen",
    path: "#",
    target: "",
    type: "",
  },
  {
    name: "Mutants",
    path: "#",
    target: "",
    type: "",
  },
  {
    name: "Alliances",
    path: "#",
    target: "",
    type: "",
  },
]

export const SiteMapMenus = [
  {
    name: "Home",
    path: "/",
    target: "",
    type: "",
  },
  {
    name: "P2E Game",
    path: "#",
    target: "",
    type: "",
  },
  {
    name: "Stake",
    path: "/stake",
    target: "",
    type: "",
  },
  {
    name: "My NFTs",
    path: "/mynfts",
    target: "",
    type: "",
  },
  {
    name: "Passes",
    path: "/pass",
    target: "",
    type: "",
  },
  {
    name: "Comics",
    path: "/comic",
    target: "",
    type: "",
  },
];

export const icons = [
  {
    name: "twitter",
    icon: "/icon/icon_twitter_red.png",
    link: "https://twitter.com/hv_origins"
  },
  {
    name: "discord",
    icon: "/icon/icon_discord_red.png",
    link: "https://discord.gg/masterbrews"
  },
  {
    name: "instagram",
    icon: "/icon/icon_insta_red.png",
    link: "https://www.instagram.com/hv_origins/"
  },
  {
    name: "magiceden",
    icon: "/icon/icon_me_red.png",
    link: "https://magiceden.io/creators/heroes_and_villains_origins"
  },
  {
    name: "solanart",
    icon: "/icon/icon_explor_red.png",
    link: "https://solanart.io/collections/heroesandvillains"
  },
];
